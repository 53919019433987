import React from "react";
import Layout from "../components/layout";
import { Grid, Container, Image } from "semantic-ui-react";
import imageClarisseMeyer from './about_ClarisseMeyer.png';
import imageSalesSolutions from './about_SalesSolutions.png';
import imageReddF from './about_ReddF.png';

const About = () => {

    return (
        <Layout>
            <Grid>
                <Grid.Row color="black" centered>
                    <Container><h1>About QuantInGo</h1></Container>
                </Grid.Row>

                {/* Company Overview */}
                <Grid.Row color='black'>
                    <Image src={imageClarisseMeyer} floated />
                    <div fluid style={{marginLeft:'30px', marginRight:'20px', marginTop:'5px'}}>
                        <h2>Company Overview</h2>
                        <p>QuantInGo is a group of highly skilled and experienced former electrical and computer engineers who have transitioned from their previous careers to become successful investors in the financial markets. With a strong background in mathematics and computer science, these individuals bring their expertise in building complex systems to their work in the financial markets. The team has developed a passion for engineering software for the financial markets, and their Quantitative Trading methodology is based on this passion.</p>
                        <p>Since 2016, QuantInGo has been dedicated to building financial systems and programming quantitative trading strategies for securities and crypto markets. More recently, they have expanded their offerings to include blockchain development, taking advantage of the opportunities in this growing field. The core team is composed of individuals with a wealth of experience and expertise in software engineering, blockchain technology, and academia. These individuals bring a combination of technical skills, critical thinking, and clear communication to the table, making them valuable assets to any project they work on.</p>
                    </div>
                </Grid.Row>

                {/* Our People */}
                <Grid.Row color='black'>
                    <Image src={imageSalesSolutions} floated />
                    <div fluid style={{marginLeft:'30px', marginRight:'20px', marginTop:'5px'}}>
                        <h2>Our People</h2>
                        <p>The team boasts a diverse range of skills and expertise that encompasses software engineering, aeronautics, nuclear and energy technologies, civil engineering, and blockchain technology. With over 20 years of experience in software engineering, they have honed their skills in all aspects of software development, including quant trading algorithms and blockchain architecture and development. Their proficiency has earned them recognition in the industry, with accolades that include awards at hackathons and a reputation as a thought leader in the blockchain space.</p>
                        <p>Their wealth of academic knowledge and experience in academia further reinforces their capacity to contribute to the team's efforts. With a background in biochemical and civil engineering, and a Master's degree in aeronautical infrastructure engineering, they bring a well-rounded perspective to the table. Their previous roles as engineering professors and their publications in academic papers and articles demonstrate their commitment to advancing the field of engineering and their ability to critically analyze complex technical subjects.</p>
                        <p>In addition to their technical proficiency, the team is also highly valued for their analytical and critical thinking skills. With a deep understanding of blockchain technology, they have made important contributions to the field bringing their analytical prowess to the table. They are also well-versed in graphic design, adding an extra dimension of creative capability to their skill set.</p>
                        <p></p>The team's clear communication and collaboration skills are paramount to their success, making them a valuable asset to any project. Their ability to work together to solve complex problems and bring their ideas to life sets them apart from their peers and sets the stage for their continued success.
                    </div>
                </Grid.Row>

                {/* Our Mission */}
                <Grid.Row color='black'>
                    <Image src={imageReddF} floated />
                    <div fluid style={{marginLeft:'30px', marginRight:'20px', marginTop:'5px'}}>
                        <h2>Our Mission</h2>
                        <p>As a team, we believe that delivering value to clients is at the forefront of our philosophy. We are committed to ensuring that every project we undertake is executed with the utmost care and precision, resulting in a tangible and measurable impact for our clients. This commitment to quality is what sets us apart in the industry and drives us to consistently exceed expectations.</p>
                        <p>Innovation and staying ahead of the curve is another key aspect of our philosophy. We recognize that technology and the world around us is constantly evolving, and it is our responsibility to stay informed and implement new ideas in our work. This focus on innovation allows us to pursue the state-of-art and offer cutting-edge solutions that not only meet, but exceed, the needs of our clients. Our unwavering dedication to customer satisfaction is demonstrated through our willingness to go above and beyond in ensuring that every project is completed to the highest standards. Our pursuit of excellence is what drives us to continuously improve and evolve our processes, allowing us to stay at the forefront of our field.</p>
                    </div>
                </Grid.Row>
            </Grid>
        </Layout>
    )
}

export default About;