import React from "react";
import Layout from '../components/layout';
import { Grid, Container, Card, Icon } from "semantic-ui-react";
import frontimage from '../frontimage.png';
import MediaQuery from "react-responsive";

const Page = () => {

    function cards() {
        return (
            <>
                <Card cardStyle>
                    <Card.Content>
                        <Icon name="microchip" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>High-Integrity Embedded Systems Design and Development</Card.Header>
                        <Card.Description>
                            We specialize in designing and developing high-integrity embedded systems that meet stringent safety and reliability requirements. Our expertise ensures your systems perform flawlessly in critical applications.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="clock outline" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Real-Time Systems Integration and Optimization</Card.Header>
                        <Card.Description>
                            Our real-time systems integration and optimization services ensure seamless interaction and optimal performance of your embedded systems in time-critical environments.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="shield alternate" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Safety-Critical Systems Analysis and Testing</Card.Header>
                        <Card.Description>
                            Our safety-critical systems analysis and testing help identify potential hazards and ensure compliance with safety standards. We rigorously test your systems to ensure their reliability and integrity.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="lock" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Cybersecurity and Threat Analysis for Embedded Systems</Card.Header>
                        <Card.Description>
                            Our cybersecurity and threat analysis services fortify your embedded systems against cyber threats and vulnerabilities. We assess and implement robust security measures to safeguard your systems and data.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="certificate" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Embedded Systems Certification and Compliance</Card.Header>
                        <Card.Description>
                            Our embedded systems certification and compliance services ensure your systems adhere to industry standards and regulations. We guide you through the certification process, enhancing trust and credibility.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="check circle outline" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Automated Testing and Quality Assurance</Card.Header>
                        <Card.Description>
                            Our automated testing and quality assurance services streamline your development process. We deploy automated tests to ensure reliability, performance, and adherence to specifications.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="cogs" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Performance Tuning and Optimization</Card.Header>
                        <Card.Description>
                            Our performance tuning and optimization services enhance the efficiency and responsiveness of your embedded systems. We optimize resource usage and minimize latency for optimal results.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="shield alternate" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Fault Tolerance and Resilience Design</Card.Header>
                        <Card.Description>
                            Our fault tolerance and resilience design services ensure your embedded systems continue to function in the presence of failures. We implement redundancy and failover mechanisms for uninterrupted operation.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="bug" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Embedded Systems Debugging and Troubleshooting</Card.Header>
                        <Card.Description>
                            Our embedded systems debugging and troubleshooting services identify and resolve issues within your systems. We ensure smooth operation and optimal performance through thorough analysis and resolution.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="sync alternate" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Continuous Integration and Deployment for Embedded Systems</Card.Header>
                        <Card.Description>
                            Our continuous integration and deployment services streamline your development workflow. We implement automated CI/CD pipelines to ensure efficient and error-free deployment of your embedded systems.
                        </Card.Description>
                    </Card.Content>
                </Card>

            </>

        )
    }
    return (
        <Layout>
            <Grid>
                <Grid.Row color="black" centered>
                    <Container><h1>High-Integrity Embedded, Real-Time and Safety-Critical Systems</h1></Container>
                </Grid.Row>

                <Grid.Row color='black'>
                    <Grid.Column size={16} style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <div style={{
                            background: `url(${frontimage}) center / cover no-repeat`,
                            width: '100%',
                            paddingBottom: '1%', // Adjust this value to maintain aspect ratio
                        }}>

                            <MediaQuery maxWidth={500}>
                                <Card.Group centered itemsPerRow={1}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>
                            <MediaQuery maxWidth={700} minWidth={500}>
                                <Card.Group centered itemsPerRow={2}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>
                            <MediaQuery minWidth={700}>
                                <Card.Group centered itemsPerRow={3}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>


                        </div>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
        </Layout>
    )
}

export default Page;