import React from "react";
import Header from './header';
import Footer from './footer';
import 'semantic-ui-css/semantic.min.css';

import { Container } from "semantic-ui-react";

const Layout = (props) =>{
    return (
        <Container fluid>
            <Header />
            {props.children}
            <Footer />
        </Container>
    )
}

export default Layout;