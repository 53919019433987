import React, { Component } from "react";
import './App.css';
import Layout from "./components/layout";
import { Grid, Image, Label, Message, Button, Divider } from "semantic-ui-react";
import frontimage from './frontimage.png';

class App extends Component {
  componentDidMount() {
    console.log('Console was called.');
  }


  render() {
    let textTitle = 'Unleash the Power of Disruptive Technology with QuantInGo';
    let textMiddle = 'Transform your portfolio with state-of-the-art solutions from a team of experts in blockchain and financial and crypto markets technology. Elevate your software development with Quantingo.';
    let textCallAction = 'Contact us today to schedule a consultation and let our experts bring your vision to life.';

    return (
      <Layout>
        <Grid centered columns={2}>

          <Grid.Column fluid color="black" textAlign="right" only='tablet computer'>
            <Image style={{ backgroundSize: 'cover', width: '100%', paddingBottom: '5%' }} spaced='left' floated src={frontimage} size="big" href='/'></Image>
          </Grid.Column>

          <Grid.Column only='computer' color='black' textAlign='center'>
            <Label color='black' size="massive">
              <Message size='massive' style={{ backgroundColor: 'transparent', color: 'white' }}>
                {textTitle}
              </Message>
              <h3>{textMiddle}</h3>
            </Label>
            <Divider hidden />
            <Button size="big"><a href="mailto:contact@quantingo.com">{textCallAction}</a></Button>
          </Grid.Column>

          <Grid.Column only='tablet' color='black' textAlign='center'>
            <Label color='black'>
              <Grid.Column verticalAlign="middle"></Grid.Column>
              <h2>{textTitle}</h2>
              <h5>{textMiddle}</h5>
            </Label>
            <Divider hidden />
            <Button size="large"><a href="mailto:contact@quantingo.com">{textCallAction}</a></Button>
          </Grid.Column>

          <Grid.Column only='mobile' mobile={16} color='black' textAlign='center'>

            <div style={{
              background: `url(${frontimage}) center / cover no-repeat`,
              width: '100%',
              paddingBottom: '100%', // Adjust this value to maintain aspect ratio
            }}>
              <Label style={{
                background: 'rgba(0, 0, 0, 0.4)',
                color: 'white',
              }}>
                <Grid.Column verticalAlign="middle"></Grid.Column>
                <h2>{textTitle}</h2>
                <h5>{textMiddle}</h5>
              </Label>
              <Divider hidden />
              <Button size="large" inverted style={{
                background: 'rgba(255, 255, 255, 0.8)', color: 'white'
              }}>
                <a href="mailto:contact@quantingo.com">{textCallAction}</a>
              </Button>


            </div>
          </Grid.Column>

        </Grid>


      </Layout>
    )
  }
}

export default App;