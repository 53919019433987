import React from "react";
import { Menu, Image, Divider, Grid, Dropdown } from 'semantic-ui-react';
import logo from './logo.png';
import MediaQuery from "react-responsive";

const Page = () => {

    const generateMenuItems = () => (
        <Menu.Menu position="right">
            <Dropdown text='Portfolio' simple item direction="left">
                <Dropdown.Menu>
                    <Dropdown.Item text='Blockchain & Web3 Applications' as='a' href='/blockchain' />
                    <Dropdown.Item text='Real-Time & Safety-Critical Systems' as='a' href='/rtscs' />
                    <Dropdown.Item text='Military Ballistic Algorithms' as='a' href='/milap' />
                    <Dropdown.Item text='​Data Science Research' as='a' href='/dtscience' />
                    <Dropdown.Item disabled text='SDLC & Process' as='a' href='/sdlc' />
                </Dropdown.Menu>
            </Dropdown>

            <Dropdown.Item link text='About' simple item direction="left" as='a' href='/about' ></Dropdown.Item>
            <Dropdown.Item link text='Contact' as='a' href='mailto:contact@quantingo.com' />
        </Menu.Menu>
    );

    return (
        <div>
            <Grid centered style={{ marginLeft: '10px', marginRight: '10px' }} columns={2}>
                <Grid.Column only="tablet computer">
                    <Image src={logo} size='medium' href='/'></Image>
                </Grid.Column>

                <Grid.Column only='tablet computer'>
                    <Menu text size='massive' secondary style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {generateMenuItems()}
                    </Menu>
                </Grid.Column>

                <Grid.Row only='mobile'>
                    <MediaQuery minWidth={448}>
                        {/* <Divider hidden /> */}
                        <Image src={logo} size='small' href='/' style={{ marginTop: '3px' }}></Image>
                        <Menu compact text size='massive' secondary style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                            {generateMenuItems()}
                        </Menu>
                    </MediaQuery>
                    <MediaQuery maxWidth={447}>
                        <Image src={logo} size='medium' href='/' />
                        <Divider />
                        <Dropdown direction='left' icon='bars' size='massive' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Dropdown.Menu>
                                <Dropdown.Header>Portfolio</Dropdown.Header>
                                <Dropdown.Item text='Blockchain & Web3 Applications' as='a' href='/blockchain' />
                                <Dropdown.Item text='Real-Time & Safety-Critical Systems' as='a' href='/rtscs' />
                                <Dropdown.Item text='Military Ballistic Algorithms' as='a' href='/milap' />
                                <Dropdown.Item text='​Data Science Research' as='a' href='/dtscience' />
                                <Dropdown.Item disabled text='SDLC & Process' as='a' href='/sdlc' />
                                <Dropdown.Divider />
                                <Dropdown.Item as='a' href='/about' >About</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item link text='Contact' as='a' href='mailto:contact@quantingo.com' />
                            </Dropdown.Menu>
                        </Dropdown>
                        <Divider />
                    </MediaQuery>


                </Grid.Row>

                {/* <Divider hidden /> */}
            </Grid>
        </div>
    )
}

export default Page;