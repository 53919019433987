import React from "react";
import Layout from '../components/layout';
import { Grid, Container, Card, Icon } from "semantic-ui-react";
import frontimage from '../frontimage.png';
import MediaQuery from "react-responsive";

const Blockchain = () => {
    function cards() {
        return (
            <>
                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="clipboard" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Audit Services</Card.Header>
                        <Card.Description>
                            Our audit services ensure the security and reliability of your blockchain applications. We perform thorough code reviews and security assessments to identify vulnerabilities and provide recommendations for enhanced performance and risk mitigation.
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="code" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Smart Contract Development</Card.Header>
                        <Card.Description>
                            Our expert developers specialize in crafting secure and efficient smart contracts. We help you translate your business logic into code that automates processes on the blockchain, ensuring transparency and trust in transactions.
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="globe" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Web3 Development</Card.Header>
                        <Card.Description>
                            Our Web3 development services focus on creating decentralized applications (DApps) that interact seamlessly with the blockchain. We utilize the power of the Ethereum ecosystem and other platforms to build user-friendly and secure DApps that provide real-world value.
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="money" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Tokenomics</Card.Header>
                        <Card.Description>
                            Our tokenomics services include designing and implementing token models that align with your business goals. We help you create and manage token economies, allowing you to optimize incentives, rewards, and governance mechanisms.
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="chart bar" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Blockchain Business & Strategy</Card.Header>
                        <Card.Description>
                            Our blockchain experts assist you in developing a comprehensive business and strategy plan. We analyze your industry, target audience, and competitors to help you integrate blockchain technology effectively and achieve your business objectives.
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="certificate" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Process & Certification</Card.Header>
                        <Card.Description>
                            Our process and certification services focus on ensuring compliance with industry standards and regulations. We help you streamline your blockchain development processes and obtain necessary certifications for trust and credibility.
                        </Card.Description>
                    </Card.Content>
                </Card>
                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="graduation cap" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Blockchain Education</Card.Header>
                        <Card.Description>
                            Our blockchain education services offer comprehensive training programs to help individuals and organizations understand the intricacies of blockchain technology. From beginner to advanced levels, we empower you with the knowledge to harness the potential of decentralized systems.
                        </Card.Description>
                    </Card.Content>
                </Card>
            </>

        )
    }
    return (
        <Layout>
            <Grid>
                <Grid.Row color="black" centered>
                    <Container><h1>Blockchain & Web3 Applications</h1></Container>
                </Grid.Row>

                <Grid.Row color='black'>
                    <Grid.Column size={16} style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <div style={{
                            background: `url(${frontimage}) center / cover no-repeat`,
                            width: '100%',
                            paddingBottom: '1%', // Adjust this value to maintain aspect ratio
                        }}>

                            <MediaQuery maxWidth={500}>
                                <Card.Group centered itemsPerRow={1}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>
                            <MediaQuery maxWidth={700} minWidth={500}>
                                <Card.Group centered itemsPerRow={2}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>
                            <MediaQuery minWidth={700}>
                                <Card.Group centered itemsPerRow={3}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>


                        </div>
                    </Grid.Column>
                </Grid.Row>




            </Grid>
        </Layout>
    )
}

export default Blockchain;