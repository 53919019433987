import React from "react";
import Layout from '../components/layout';
import { Grid, Container, Card, Icon } from "semantic-ui-react";
import frontimage from '../frontimage.png';
import MediaQuery from "react-responsive";

const Page = () => {

    function cards() {
        return (
            <>
                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="shield" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Development of Custom Ballistic Algorithms for Military Applications</Card.Header>
                        <Card.Description>
                            Our custom ballistic algorithms are tailored to meet the specific requirements of military applications. We design algorithms that provide accurate and reliable calculations for a range of ballistic scenarios.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="sitemap" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Integration of Existing Ballistic Algorithms into Military Systems</Card.Header>
                        <Card.Description>
                            We integrate proven ballistic algorithms into various military systems, enhancing their capabilities and accuracy. Our expertise ensures seamless integration and compatibility with existing systems.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="line chart" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Analysis and Optimization of Existing Ballistic Algorithms</Card.Header>
                        <Card.Description>
                            Our analysis and optimization services enhance the accuracy and performance of existing ballistic algorithms. We fine-tune calculations and parameters for optimal results in military applications.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="crosshairs" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Design and Implementation of Real-Time Ballistic Tracking Systems</Card.Header>
                        <Card.Description>
                            Our real-time ballistic tracking systems enable precise monitoring of weapon trajectories. We develop and implement tracking solutions that enhance accuracy and support military operations.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="cogs" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Creation of Simulations and Models for Testing and Evaluation</Card.Header>
                        <Card.Description>
                            Our simulations and models provide a robust environment for testing and evaluating ballistic algorithms. We create accurate simulations to assess algorithm performance and identify improvements.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="rocket" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Design and Implementation of Advanced Guidance and Navigation Systems</Card.Header>
                        <Card.Description>
                            Our guidance and navigation systems enhance the precision of military vehicles and weapons. We develop advanced systems that improve accuracy and ensure successful mission outcomes.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="eye" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Integration of Advanced Sensors and Data Analysis Techniques</Card.Header>
                        <Card.Description>
                            Our integration services enhance ballistic systems with cutting-edge sensors and data analysis techniques. We enable real-time data acquisition and analysis for accurate trajectory calculations.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="handshake outline" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Consultancy and Support for Ballistic Algorithm Integration</Card.Header>
                        <Card.Description>
                            Our consultancy and support services guide the seamless integration of ballistic algorithms into existing military systems and equipment. We provide expert guidance and assistance for successful implementation.
                        </Card.Description>
                    </Card.Content>
                </Card>
            </>

        )
    }
    return (
        <Layout>
            <Grid>
                <Grid.Row color="black" centered>
                    <Container><h1>Millitary Ballistic Algorithms</h1></Container>
                </Grid.Row>

                <Grid.Row color='black'>
                    <Grid.Column size={16} style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <div style={{
                            background: `url(${frontimage}) center / cover no-repeat`,
                            width: '100%',
                            paddingBottom: '1%', // Adjust this value to maintain aspect ratio
                        }}>

                            <MediaQuery maxWidth={500}>
                                <Card.Group centered itemsPerRow={1}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>
                            <MediaQuery maxWidth={700} minWidth={500}>
                                <Card.Group centered itemsPerRow={2}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>
                            <MediaQuery minWidth={700}>
                                <Card.Group centered itemsPerRow={3}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>


                        </div>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
        </Layout>
    )
}

export default Page;