import React from "react";
import Layout from '../components/layout';
import { Grid, Container, Card, Icon } from "semantic-ui-react";
import frontimage from '../frontimage.png';
import MediaQuery from "react-responsive";

const Page = () => {

    function cards() {
        return (
            <>
                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="chart line" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Data Analytics and Business Intelligence</Card.Header>
                        <Card.Description>
                            Our data analytics and business intelligence services provide insights into your data, enabling informed decision-making and improved business strategies.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="line graph" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Predictive Maintenance and Optimization</Card.Header>
                        <Card.Description>
                            We utilize predictive maintenance techniques to optimize operations, reduce downtime, and extend the lifespan of critical assets.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="users" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Customer Segmentation and Personalization</Card.Header>
                        <Card.Description>
                            Our customer segmentation and personalization services help tailor marketing strategies and enhance customer engagement through targeted approaches.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="line chart" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Predictive Sales and Marketing</Card.Header>
                        <Card.Description>
                            We predict sales trends and provide insights for effective marketing strategies, helping businesses maximize their revenue and market presence.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="shield" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Fraud Detection and Prevention</Card.Header>
                        <Card.Description>
                            Our fraud detection and prevention solutions use advanced algorithms to identify and prevent fraudulent activities, safeguarding your business.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="time" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Predictive Resource Allocation and Scheduling</Card.Header>
                        <Card.Description>
                            We optimize resource allocation and scheduling using predictive models, ensuring efficient utilization of assets and minimizing costs.
                        </Card.Description>
                    </Card.Content>
                </Card>
            </>

        )
    }
    return (
        <Layout>
            <Grid>
                <Grid.Row color="black" centered>
                    <Container><h1>Data Science Research</h1></Container>
                </Grid.Row>

                <Grid.Row color='black'>
                    <Grid.Column size={16} style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <div style={{
                            background: `url(${frontimage}) center / cover no-repeat`,
                            width: '100%',
                            paddingBottom: '20%', // Adjust this value to maintain aspect ratio
                        }}>

                            <MediaQuery maxWidth={500}>
                                <Card.Group centered itemsPerRow={1}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>
                            <MediaQuery maxWidth={700} minWidth={500}>
                                <Card.Group centered itemsPerRow={2}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>
                            <MediaQuery minWidth={700}>
                                <Card.Group centered itemsPerRow={3}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>


                        </div>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
        </Layout>
    )
}

export default Page;