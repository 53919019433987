import React from "react";
import Layout from '../components/layout';
import { Grid, Container, Card, Icon } from "semantic-ui-react";
import frontimage from '../frontimage.png';
import MediaQuery from "react-responsive";

const Page = () => {

    function cards() {
        return (
            <>
                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="tasks" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Requirements Gathering and Analysis</Card.Header>
                        <Card.Description>
                            We gather and analyze project requirements to ensure a clear understanding of your goals and objectives.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="desktop" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>User Interface Design</Card.Header>
                        <Card.Description>
                            Our expert designers create intuitive and user-friendly interfaces that enhance user experiences.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="sitemap" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Architecture and Technical Design</Card.Header>
                        <Card.Description>
                            Our skilled architects design robust and scalable solutions aligned with your technical requirements.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="code" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Development and Implementation</Card.Header>
                        <Card.Description>
                            We develop and implement high-quality software solutions tailored to your specific needs.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="exchange" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Integration and Testing</Card.Header>
                        <Card.Description>
                            Our rigorous testing and seamless integration processes ensure the reliability and performance of your software.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="cloud upload" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Deployment and Maintenance</Card.Header>
                        <Card.Description>
                            We provide deployment strategies and ongoing maintenance to keep your software up-to-date and running smoothly.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="cogs" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Software Process</Card.Header>
                        <Card.Description>
                            We follow industry best practices and methodologies to ensure efficient software development processes.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="chart line" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Agile Methodologies, Maturity Models</Card.Header>
                        <Card.Description>
                            We implement Agile methodologies and leverage maturity models to ensure efficient and iterative development.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="trophy" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Military Process Development (MIL-STD)</Card.Header>
                        <Card.Description>
                            We adhere to military standards for process development to ensure robust and compliant software solutions.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="cogs" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Automated Testing and Continuous Integration</Card.Header>
                        <Card.Description>
                            We employ automated testing and continuous integration to deliver high-quality software with efficiency.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="clipboard check" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Quality Assurance and Project Management</Card.Header>
                        <Card.Description>
                            We ensure quality through rigorous QA processes and effective project management for successful outcomes.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="tasks" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Requirements Gathering and Analysis</Card.Header>
                        <Card.Description>
                            We meticulously gather and analyze project requirements for clear project scope and objectives.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="pencil alternate" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>User Experience Design</Card.Header>
                        <Card.Description>
                            We prioritize creating intuitive and enjoyable user experiences that align with your product's goals.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="shield alternate" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Security and Data Privacy Considerations</Card.Header>
                        <Card.Description>
                            We implement robust security measures and adhere to data privacy regulations to protect user data.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="cogs" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Deployment and Maintenance Planning</Card.Header>
                        <Card.Description>
                            We provide strategic deployment planning and ongoing maintenance to ensure your software's longevity.
                        </Card.Description>
                    </Card.Content>
                </Card>

                <Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}>
                    <Card.Content>
                        <Icon name="file alternate outline" size="massive" style={{ color: '#1B1C1D', marginBottom: '20px' }} />
                        <Card.Header>Documentation and Training for End-Users</Card.Header>
                        <Card.Description>
                            We offer comprehensive documentation and user training to ensure smooth user onboarding and adoption.
                        </Card.Description>
                    </Card.Content>
                </Card>

            </>

        )
    }
    return (
        <Layout>
            <Grid>
                <Grid.Row color="black" centered>
                    <Container><h1>Software Development Lifecycle & <p>Software Process</p></h1></Container>
                </Grid.Row>

                <Grid.Row color='black'>
                    <Grid.Column size={16} style={{ marginLeft: '20px', marginRight: '20px' }}>
                        <div style={{
                            background: `url(${frontimage}) center / cover no-repeat`,
                            width: '100%',
                            paddingBottom: '1%', // Adjust this value to maintain aspect ratio
                        }}>

                            <MediaQuery maxWidth={500}>
                                <Card.Group centered itemsPerRow={1}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>
                            <MediaQuery maxWidth={700} minWidth={500}>
                                <Card.Group centered itemsPerRow={2}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>
                            <MediaQuery minWidth={700}>
                                <Card.Group centered itemsPerRow={3}>
                                    {cards()}
                                </Card.Group>
                            </MediaQuery>


                        </div>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
        </Layout>
    )
}

export default Page;