import React from "react";
import { Icon, Menu} from "semantic-ui-react";

const Page = () => {
    
    return (
        <div>
            
            <Menu text style={{ display: 'flex', justifyContent: 'center' }} icon='copyright outline'>
                <Menu.Item link href='/'>
                    <Icon name='copyright outline'/>2023 QuantInGo
                </Menu.Item>
                
            </Menu>
            
        </div>
    )
}

export default Page;