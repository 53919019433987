import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Blockchain from './pages/blockchain';
import Rtscs from './pages/rtscs';
import MilAp from './pages/milap';
import Dtscience from './pages/dtscience';
import Sdlc from './pages/sdlc';

import About from './pages/about';

function Pages(){
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<App />} />
        <Route exact path='/blockchain' element={<Blockchain />} />
        <Route exact path='/rtscs' element={<Rtscs />} />
        <Route exact path='/milap' element={<MilAp />} />
        <Route exact path='/dtscience' element={<Dtscience />} />
        <Route exact path='/sdlc' element={<Sdlc />} />
        <Route exact path='/about' element={<About />} />
      </Routes>
    </BrowserRouter>
  )
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Pages />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
